<template>
  <v-sheet>
    <div class="pa-4">
      <h4 class="title">Чат с участниками</h4>
    </div>
    <v-card  flat height="500">
      <v-progress-circular v-if="loading" class="mt-5 mx-auto" color="primary"
                           indeterminate width="2"/>
      <SmartChatComponent v-else-if="$store.state.projects.currentProjectChat"
                          :chat_id="$store.state.projects.currentProjectChat.id"/>
      <p v-else> {{ error }}</p>
    </v-card>

  </v-sheet>
</template>

<script>
import SmartChatComponent from "@/components/Messages/SmartChatComponent.vue";

    export default {
      name: "ProjectsItemChatComponent",
      components: {SmartChatComponent},
      computed: {
        project() {
          return this.$store.state.projects.currentProject;
        },
      },
      data() {
        return {
          loading: true,
          error: ''
        }
      },
      mounted() {
        if (!this.$store.state.projects.currentProject.permissions.can_see_chat) {
          this.$router.back()
          return;
        }
        this.$store.commit('setCurrentProjectChat', null);
        this.$store.dispatch('getProjectChat').then(() => {
          this.loading = false;
        }).catch((e) => {
          this.error = e.response.data.message
        }).finally(() => {
          this.loading = false;
        })
      },
    }
</script>

<style scoped>

</style>
